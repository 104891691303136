<template>
  <div>
    <v-alert v-show="show_msg" :type="msg_type">
      {{ errors }}
    </v-alert>
    <b-table :fields="column" striped hover :items="items">
      <template #cell(actions)="row">
        <b-button size="sm" variant="primary" @click="editStore(row.item.id)"> Editar </b-button>
        <b-button size="sm" variant="danger" @click="deletarStore(row.item.id, row.index)"> Deletar </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  name: "User",
  data() {
    return {
      column: [
        { key: "id", sortable: true, label: "id" },
	{ key: "pla_title", sortable: true, label: "pla_title" },
	{ key: "pla_subtitle", sortable: true, label: "pla_subtitle" },
	{ key: "pla_price", sortable: true, label: "pla_price" },
	{ key: "pla_discount_month", sortable: true, label: "pla_discount_month" },
	{ key: "pla_discount_year", sortable: true, label: "pla_discount_year" },
	{ key: "pla_info_data", sortable: true, label: "pla_info_data" },
	{ key: "pla_text", sortable: true, label: "pla_text" },
	{ key: "pla_active", sortable: true, label: "pla_active" },
	{ key: "created", sortable: true, label: "created" },
	{ key: "modified", sortable: true, label: "modified" }
	
      ],
      errors: [],
      show_msg: false,
      msg_type: null,
      items: null,
    };
  },
  mounted() {
    ApiService.get("Plans/get")
      .then(({ data }) => {
        this.items = data.plans;
      })
      .catch(({ response }) => {
        this.errors = response.data.errors;
        this.msg_type = "error";
        this.show_msg = true;
      });
  },
  computed: {

  },
  methods: {
    editStore(id) {
      this.$router.push('/Plans/edit/'+ id);
    },
    deletarStore(id, index) {

      ApiService.post("Plans/delete", {
        id: id ,
      })
        .then(({ data }) => {
          this.errors = data.message;
          this.show_msg = true;
          this.msg_type = "success";
          this.items.splice(index,1);
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.msg_type = "error";
          this.show_msg = true;
        });
    },
  },
  components: {},
};
</script>
